import { getTypeQuestionnaires } from "./getTypeQuestionnaires";
import { OrgTypeEnum } from "~/typings/types";

export const getCountOfStepsInAnketa = ({ orgType }: { orgType: OrgTypeEnum }) => {
  if (getTypeQuestionnaires() === "partner") {
    if (orgType === OrgTypeEnum.Company) {
      return 4;
    }
    return 5;
  }

  return 9;
};
